var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.eventsDate == "" ? _vm.$refs.calendar?.title : _vm.eventsDate)+" ")]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-select',{attrs:{"items":[
            { value: 'week', text: _vm.$t('EREPORTER.SCHEDULE.WEEK') },
            { value: 'month', text: _vm.$t('EREPORTER.SCHEDULE.MONTH') }
          ],"hide-details":"","solo":""},on:{"change":_vm.changeType},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"_btn-shadow",attrs:{"color":"primary","disabled":_vm.forbiddenDateRange,"large":"","outlined":""},on:{"click":function($event){_vm.$refs.calendar?.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"_btn-shadow",attrs:{"color":"primary","large":"","outlined":""},on:{"click":function($event){_vm.$refs.calendar?.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-calendar',{ref:"calendar",staticClass:"_intervalless _box-shadow",attrs:{"color":"primary","event-color":"white","locale":_vm.appLanguage,"type":_vm.type,"weekdays":_vm.weekday,"interval-count":0},on:{"change":_vm.updateSlots},scopedSlots:_vm._u([{key:"day-label",fn:function({ date, day }){return [_c('v-badge',{attrs:{"color":_vm.slotsIsset(date) ? 'primary' : 'transparent',"offset-x":"24","offset-y":"12","bottom":""}},[_c('v-btn',{class:{
                  'grey lighten-2': _vm.selectedDateSlotsIsset(date) && !_vm.dateIsToday(date)
                },attrs:{"large":"","icon":"","disabled":!_vm.slotsIsset(date)},on:{"click":function($event){return _vm.showSlots({ date })}}},[_c('span',{class:{ 'font-weight-black primary--text': _vm.dateIsToday(date) }},[_vm._v(_vm._s(day))])])],1)]}},{key:"day-label-header",fn:function({ date, day }){return [_c('v-badge',{attrs:{"color":_vm.slotsIsset(date) ? 'primary' : 'transparent',"offset-x":"24","offset-y":"12","bottom":""}},[_c('v-btn',{class:{
                  'grey lighten-2': _vm.selectedDateSlotsIsset(date) && !_vm.dateIsToday(date)
                },attrs:{"disabled":!_vm.slotsIsset(date),"large":"","icon":""},on:{"click":function($event){return _vm.showSlots({ date })}}},[_c('span',{class:{ 'font-weight-black primary--text': _vm.dateIsToday(date) }},[_vm._v(_vm._s(day))])])],1)]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1),(_vm.currentSlotData)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_vm._l((_vm.currentSlotData.slots),function(slot,i){return _c('v-col',{key:i,attrs:{"cols":"6"}},[_c('v-lazy',{attrs:{"transition":"slide-x-transition"}},[_c('v-btn',{staticClass:"text-none rounded-lg _btn-shadow",attrs:{"color":"primary","block":"","large":"","outlined":""},on:{"click":function($event){return _vm.$emit('show-slot-dialog', slot)}}},[_vm._v(" "+_vm._s(_vm._f("format")(slot.start,"HH:mm"))+"—"+_vm._s(_vm._f("format")(slot.end,"HH:mm"))+" ")])],1)],1)}),(_vm.currentSlotData.slots.length == 0)?_c('v-col',{staticClass:"text-center font-weight-bold",attrs:{"cols":"10","offset":"1"}},[_vm._v(" Unfortunately, the date you have chosen is fully booked. Please select a different date. ")]):_vm._e()],2)],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }