
import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";
import { CalendarEvent } from "vuetify";
import moment, { unitOfTime } from "moment";

import { AvailableSlotData } from "../store";

@Component({
  filters: {
    format(value: string, format: string) {
      if (!value) return;

      return moment(value).format(format);
    }
  }
})
export default class AvailableSlotsCalendarComponent extends Vue {
  @Model("input", { type: Object }) readonly formData!: Record<string, any>;
  @Prop(Array) readonly availableSlots!: AvailableSlotData[];

  @Emit()
  input() {
    return this.localFormData;
  }

  @Watch("localFormData", { deep: true })
  onLocalFormDataChanged() {
    this.input();
  }

  @Watch("availableSlots", { deep: true })
  onAvailableSlotsChanged(data: AvailableSlotData[]) {
    if (this.initialRequest) {
      this.showSlots({ date: this.formData.date_from });
      this.initialRequest = false;
    }

    if (this.typeChanged && this.type == "week" && this.eventsDate == "") {
      this.showSlots({ date: moment().format("YYYY-MM-DD") });
      this.typeChanged = false;
    }
  }

  localFormData = this.formData;

  type = "week";
  eventsDate = "";
  focus = this.localFormData.date_from;
  typeChanged = false;
  initialRequest = true;
  weekday = [1, 2, 3, 4, 5, 6, 0];
  currentSlotData: null | AvailableSlotData = null;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get calendar(): CalendarEvent {
    return this.$refs.calendar as CalendarEvent;
  }

  get forbiddenDateRange(): boolean {
    return moment(this.focus)
      .startOf(this.type as unitOfTime.StartOf)
      .isSameOrBefore(moment().startOf(this.type as unitOfTime.StartOf));
  }

  get slotData() {
    return this.availableSlots.map(data =>
      Object.freeze({
        date: moment(data.date).format("YYYY-MM-DD"),
        slots: data.slots
      })
    );
  }

  changeType(type: string) {
    this.typeChanged = true;
  }

  showSlots({ date }) {
    const _moment = moment(date);

    this.focus = date;
    this.currentSlotData = null;

    this.localFormData.date_from = _moment.format("YYYY-MM-DD");
    this.localFormData.date_to = _moment.format("YYYY-MM-DD");

    this.$nextTick(() => {
      this.eventsDate = _moment.locale(this.appLanguage).format("D MMMM, Y");
      this.currentSlotData = this.slotData.filter(_ => _.date == date)[0];
    });
  }

  updateSlots({ start, end }) {
    let startDate = moment(start.date);
    const endDate = moment(end.date).endOf("month");

    if (this.typeChanged) {
      if (this.eventsDate != "") {
        this.typeChanged = false;
        return;
      } else if (this.type == "month") {
        this.typeChanged = false;
        return;
      }

      if (this.type == "week") {
        startDate = moment();

        this.$emit("fetch-available-slots-with-params", {
          date_from: startDate.format("YYYY-MM-DD"),
          date_to: startDate.endOf("month").format("YYYY-MM-DD")
        });

        return;
      }
    }

    if (startDate.isSameOrBefore(moment())) {
      startDate = moment();
    } else if (startDate.month() == moment().month()) {
      startDate = moment();
    } else {
      startDate = startDate.startOf("month");
    }

    this.$emit("fetch-available-slots-with-params", {
      date_from: startDate.format("YYYY-MM-DD"),
      date_to: endDate.format("YYYY-MM-DD")
    });

    this.eventsDate = "";
    this.currentSlotData = null;
  }

  dateIsToday(date: string): boolean {
    return moment(date).isSame(moment().format("YYYY-MM-DD"));
  }

  slotsIsset(date: string): boolean {
    return this.slotData.filter(_ => _.date == date).length > 0;
  }

  selectedDateSlotsIsset(date: string): boolean {
    return (
      moment(this.localFormData.date_from).isSame(moment(date), "day") && this.eventsDate != ""
    );
  }
}
